<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Create a Scenario'"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Create a Scenario</span>
      </v-card-title>
      <common-page-loader v-if="loading" />
      <common-trs-card class="mx-6 pb-0 mb-2">
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="px-0">
            <scenario-form
              v-model="scenarioData"
              :editable="false"
            />
          </v-card-text>

          <v-row class="mx-6">
            <v-divider />
          </v-row>
          <v-row class="d-flex justify-space-between mx-6">
            <v-col
              class="pl-0 pt-0"
            >
              <common-trs-btn
                type="secondary"
                medium
                class="pull-right mr-2"
                text
                :to="{name: 'Scenarios'}"
              >
                Cancel
              </common-trs-btn>
            </v-col>
            <v-col
              class="text-right pl-0 pt-0 pr-0"
            >
              <common-trs-btn
                type="primary"
                medium
                class="white--text"
                text
                :disabled="!valid"
                :loading="loading"
                @click="save"
              >
                Save
              </common-trs-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import ScenarioForm from '@/components/common/captable/components/forms/ScenarioForm'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'AddScenarios',

    components: {
      ScenarioForm,
    },

    data: () => ({
      scenarioData: {},
      loading: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    methods: {
      async save () {
        this.loading = true
        try {
          await captableService.addScenario(this.captableId, this.scenarioData)
          this.loading = false
          this.$store.dispatch('app/message', { text: 'Scenario is created successfully.' })
          this.$router.push({ name: 'Scenarios' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to create scenario. Please try again later.',
          })
        }
      },
      close () {
        this.$router.push({ name: 'Scenarios' })
      },
    },
  }
</script>
